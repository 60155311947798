module.exports = {
  siteTitle: "MyZone - ashramblr", // Navigation and Site Title
  siteTitleAlt: "MyZone - ashramblr", // Alternative Site title for SEO
  siteTitleShort: "MyZone - ashramblr", // short_name for manifest
  siteProtocolLessUrl: "myzone.ashramblr.org",
  siteUrl: process.env.ROOT_URL || "https://myzone.ashramblr.org", // Domain of your site. No trailing slash!
  lang: "en", // Language Tag on <html> element
  pathPrefix: "/",
  siteLogo: "/logo.png", // Used for SEO and manifest, path to your image you placed in the 'static' folder
  siteDescription:
    "myzone.ashramblr.org is the official website of saint Shri Asharam ji Bapu Ashram Bengaluru",

  minibio: "myZone.ashramblr.org is the official website of saint Shri Asharam ji Bapu Ashram Bengaluru",
  author: "ashramblr", // Author for schemaORGJSONLD
  organization: "Saint Shri asharam ji Bapu Ashram Bengaluru",
  keywords: ["bapu ji", "saint shri asharam ji Bapu", "asharam ji bapu", "hariom wale bapu"],

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: "@ashramblr", // Twitter Username
  ogSiteName: "ashramblr", // Facebook Site Name
  ogLanguage: "en_US",

  // Manifest and Progress color
  themeColor: "#ff9933",
  backgroundColor: "3399ff", //'#231C42',

  // Social component
  twitter: "https://twitter.com/ashramblr",
  twitterHandle: "@ashramblr",
  getAbsoluteUrl: function (slug) {
    if (process.env.NODE_ENV === "production") {
      slug = this.siteUrl + slug;
    }
    else {
      slug = "http://localhost:8000" + slug;
    }

    return slug;

  }
}
